import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ruby-API-rest-routes-constraints-namespaces",
  "date": "2015-03-15",
  "title": "RUBY API – REST ROUTES, CONSTRAINTS & NAMESPACES",
  "author": "admin",
  "tags": ["development", "ruby"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article, we are going to study REST, routes, constraints, and namespaces. Before we dive into these concepts let’s take a look at how web-based services in the perspective of web users."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Let's look at an example application that uses a Web API as a service.`}</p>
    <h2>{`How does a video streaming service work?`}</h2>
    <p>{`You can create a channel say `}<strong parentName="p">{`Channel A`}</strong>{` from your web application. Then you can access `}<strong parentName="p">{`Channel A`}</strong>{` from your mobile app. So as you can see your Web API can provide data for all sort of different applications.`}</p>
    <h2>{`How the client & server communicate?`}</h2>
    <p>{`The communication from the client to server and back to the client of the above application can be described as follows:`}</p>
    <p>{`Client ---> CREATE /channels video1, video2, video3 ---> Server
Client <--- OK channel12 was created.------------------ Server`}</p>
    <p>{`Client --- READ /channels/12 --------> Server
Client <-- OK video1,video2,video3 --- Server`}</p>
    <p>{`Client --- READ /channels ----------------------> Server
Client <-- OK chennel10, channel11, channel12 --- Server`}</p>
    <p>{`Client --- READ /channels/fake10 --> Server
Client <-- ERROR invalid channel --- Server`}</p>
    <p>{`Notice the pattern here: A set of `}<strong parentName="p">{`commands`}</strong>{` performed on `}<strong parentName="p">{`things`}</strong>{` generating `}<strong parentName="p">{`responses`}</strong>{`: the very foundation of a REST API.`}</p>
    <h2>{`REST`}</h2>
    <p>{`REST stands for REpresentational State Transfer. By following a strict set of operations, REST allows us to build an infrastructure that can be supported widely with different types of applications.`}</p>
    <h2>{`Where does our API start?`}</h2>
    <p>{`A common practice for implementing our API is starting with `}<strong parentName="p">{`Routes`}</strong>{` file.`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :students
`}</code></pre>
    <p>{`In Rails, command line let's create routes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`rake routes
`}</code></pre>
    <p>{`This lists all the routes, URIs, `}<inlineCode parentName="p">{`Controller#actions`}</inlineCode>{`, and URL helpers available on our app.`}</p>
    <p><img parentName="p" {...{
        "src": "http://i.stack.imgur.com/EA98I.png",
        "alt": "Controller#actions"
      }}></img></p>
    <h2>{`Restricting Routes with options`}</h2>
    <p>{`We might not need all these routes, the resources method gives us options to restrict routes to optimize memory as well as speeds up the routing process.`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :students, except: :destroy
`}</code></pre>
    <p>{`Here we are excluding the destroy route above which has a DELETE method.
To `}<strong parentName="p">{`only`}</strong>{` create one route we can use `}<strong parentName="p">{`only`}</strong>{` option.`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :students, only: :index
`}</code></pre>
    <p>{`This will make index as the only reachable action.`}</p>
    <h2>{`Restricting routes with multiple actions`}</h2>
    <p>{`Both `}<strong parentName="p">{`:only`}</strong>{` and `}<strong parentName="p">{`:except`}</strong>{` can also take an array of actions.`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :students, only: [:index, :show]
resources :teachers, only: [:destroy, :edit, :update]
`}</code></pre>
    <h2>{`Using `}<inlineCode parentName="h2">{`with_options`}</inlineCode>{` on routes`}</h2>
    <p>{`The `}<strong parentName="p">{`with_options`}</strong>{` method is an elegant way to factor duplication out of options passed to a series of method calls. `}</p>
    <p>{`Take a look at the routes file below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :students, only: :index
resources :teachers, only: :index
resoucres :principles, only: :index 
`}</code></pre>
    <p>{`Let's use with_options so the options passed as arguments are automatically added to the resources.`}</p>
    <h2>{`Using constraints to enforce subdomain`}</h2>
    <p>{`Keeping out API under its own `}<strong parentName="p">{`subdomain`}</strong>{` allows `}<strong parentName="p">{`load balancing`}</strong>{` traffic at the DNS level, way faster than doing it at the application level.`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :courses      # http://school.com/courses
resources :students, constraints: { subdomain: 'api' }   # http://api.school.com/students
resources :teachers, constraints: { subdomain: 'api' }   # http://api.school.com/teachers
`}</code></pre>
    <p>{`Let's use `}<strong parentName="p">{`contraints`}</strong>{` to rewrite above code:`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :courses      

constraints subdomain: 'api' do
    resources :students
    resources :teachers
end
`}</code></pre>
    <h2>{`Network subdomain in development`}</h2>
    <p>{`Network configuration for supporting subdomains in development.`}</p>
    <p>{`In order to develop this locally, we have done a few things in our host file. Add the following entries into your host file.`}</p>
    <p><strong parentName="p">{`etc/hosts.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`127.0.0.1 school-dev.com
127.0.0.1 api.school-dev.com
`}</code></pre>
    <p>{`Now your URLs will look as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://school-dev.com:3000
http://api.school-dev.com:3000
`}</code></pre>
    <p>{`Don't forget the port number is still required. There are other alternatives you can use:`}</p>
    <ul>
      <li parentName="ul">{`POW - `}<a parentName="li" {...{
          "href": "http://pow.cx"
        }}>{`http://pow.cx`}</a></li>
      <li parentName="ul">{`LVH.me - `}<a parentName="li" {...{
          "href": "http://lvh.me"
        }}>{`http://lvh.me`}</a></li>
    </ul>
    <h2>{`Keeping Web and API controllers organized`}</h2>
    <p>{`It's not uncommon to use the same Rails code base for both web site and Web API.`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :pages   # serves web site, not a web API      

constraints subdomain: 'api' do
    resources :students
end
`}</code></pre>
    <p>{`This will render web API and the web site controllers under the same folder. It's a good practice to keep web controllers `}<strong parentName="p">{`separate`}</strong>{` from API controllers.
`}<strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`resources :pages   # serves web site, not a web API      

constraints subdomain: 'api' do
    names :api do
        resources :students
    end
end
`}</code></pre>
    <p>{`This will result in web API controllers having their own namespace.`}</p>
    <p><strong parentName="p">{`app/controllers/api/students_controller.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`module Api
   class ZombiesController < ApplicationController
   end
end
`}</code></pre>
    <p>{`Web API controllers are part of the API module.`}</p>
    <p><strong parentName="p">{`app/controllers/pages_controller.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`class PagesController < ApplicationController
end
`}</code></pre>
    <p>{`Web site controllers remain on top-level namespace`}</p>
    <h2>{`Removing duplication from the URL`}</h2>
    <p>{`Enforcing both subdomain namespace as well as API namespace, creates duplication:`}</p>
    <p><a parentName="p" {...{
        "href": "http://api.school.com/api"
      }}>{`http://api.school.com/api`}</a></p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`constraints subdomain: 'api' do
    namespace :api do
        resources :students
    end
end
`}</code></pre>
    <p>{`We can eliminate the duplication by passing  a `}<strong parentName="p">{`path`}</strong>{` URI`}</p>
    <p><strong parentName="p">{`config/routes.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`constraints subdomain: 'api' do
    namespace :api, path: '/' do
        resources :students
    end
end
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "http://api.school.com/"
      }}>{`http://api.school.com/`}</a></p>
    <p>{`We can use a short syntax by passing in the path and the constraints as options to the namespace method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`namespace :api, path: '/', constraints: { subdomain: 'api'} do
    resources :students
end
`}</code></pre>
    <h2>{`API case consistency`}</h2>
    <p>{`To make api all-caps, we need to add an acronym:`}</p>
    <p><strong parentName="p">{`config/initializers/inflections.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`ActiveSupport::Inflector.inflections(:en) do |inflect|
    inflect.acronym 'API'
end
`}</code></pre>
    <p>{`Now we can write our API module in all-caps:`}</p>
    <p><strong parentName="p">{`app/controllers/api/students_controller.rb`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`module API
   class ZombiesController < ApplicationController
   end
end
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      